<template>
  <div class="order-details-demo page-info-content">
    <!-- 订单详情 -->
    <div class="order-state">
      <p class="order-state-text">
        订单状态：<span>{{
          (
            dictionary.find(
              (item) => item.dictId === detailsObj.tradeOrder.state
            ) || {}
          ).dictName
        }}</span>
      </p>
      <!-- <p
        class="order-state-cancel"
        v-if="
          detailsObj?.tradeOrder?.state === '00' ||
          detailsObj?.tradeOrder?.state === '01'
        "
      >
        您可以：<el-button @click="cancelOrder">取消订单</el-button>
      </p> -->
      <!-- 取消订单  是两个平台都有的   剩下的是买家才有的 -->
      <div v-if="$store.state.userCmpRole === '02'" />
    </div>

    <!-- 商品信息 -->
    <el-descriptions title="商品信息" :column="3">
      <el-descriptions-item label="下单时间">
        {{
          detailsObj.tradeOrder.createTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="订单编号">
        {{
          detailsObj.tradeOrder.orderNo
        }}
      </el-descriptions-item>
      <el-descriptions-item label="订单来源">
        {{
          detailsObj.tradeOrder.orderSource === "00"
            ? "前台下单"
            : "后台录入"
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="商家"
      >
        {{ detailsObj.tradeOrder.cmpName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="商家联系人"
      >
        {{
          detailsObj.tradeOrder.listingPerson ? `${detailsObj.tradeOrder.listingPerson} ${detailsObj.tradeOrder.personPhone}` : ''
        }}
      </el-descriptions-item>
      <el-descriptions-item label="买家">
        {{
          detailsObj.tradeOrder.buyerCmpName
        }}
      </el-descriptions-item>
      <el-descriptions-item :span="3" label="买家联系人">
        {{
          `${detailsObj.tradeOrder.receivingPerson} ${detailsObj.tradeOrder.receivingPhone}`
        }}
      </el-descriptions-item>

      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3" label="">
        <Table
          :item-data="goodsItemData"
          :list-data="productLists"
          :loading="loading"
        />
        <!-- 点击更多按钮当显示完成之后  就不要显示了 -->
        <p
          v-if="
            detailsObj.productLists && detailsObj.productLists.length > 5 &&
              detailsObj.productLists && detailsObj.productLists.length > productLists.length
          "
          class="more"
          @click="getMoreData"
        >
          展开全部{{
            detailsObj.productLists && detailsObj.productLists.length || 0
          }}条商品<i class="el-icon-arrow-down" />
        </p>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3" label="">
        <div class="flexBox flexBox2">
          <p>商品共计：{{ detailsObj.totalWeight }}{{ unit }}</p>
          <div
            v-if="detailsObj.tradeOrder.orderAmount"
            class="amount"
          >
            <p>
              <span>货款金额</span>￥{{
                detailsObj.tradeOrder.orderAmount || 0
              }}
            </p>
            <p v-if="detailsObj.tradeOrder.merchantDistribution === 1">
              <span>运费</span>￥
              {{
                detailsObj.tradeOrder.orderFreight
                  ? detailsObj.tradeOrder.orderFreight || 0
                  : "待确认"
              }}
            </p>
            <p v-if="detailsObj.tradeOrder.payStyle === '01'">
              <span>保证金金额</span>￥{{ detailsObj.tradeOrder.bondAmount || 0 }}
            </p>
            <p class="price">
              <span>订单总额</span>￥{{
                detailsObj.tradeOrder.orderTotalAmount
              }}{{
                detailsObj.tradeOrder.merchantDistribution === 1&&!detailsObj.tradeOrder.orderFreight ? "(不含运费)" : ""
              }}
            </p>
            <p class="price">
              <span>已付总额</span>￥{{
                detailsObj.tradeOrder.orderActualAmount || 0
              }}
            </p>
          </div>
          <div v-else class="amount">
            <p><span>货款金额</span>-</p>
            <p v-if="detailsObj.tradeOrder.merchantDistribution === 1">
              <span>运费</span>-
            </p>
            <p v-if="detailsObj.tradeOrder.payStyle === '01'">
              <span>保证金金额</span>-
            </p>
            <p class="price">
              <span>订单总额</span>-
            </p>
            <p class="price">
              <span>已付总额</span>-
            </p>
          </div>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <!-- 配送信息 -->
    <el-descriptions title="配送信息" :column="1" class="distribution-info">
      <el-descriptions-item label="配送方式">
        {{
          detailsObj.tradeOrder.buyerPickUp === 1
            ? "买家自提"
            : detailsObj.tradeOrder.merchantDistribution === 1
              ? "商家配送"
              : "过户"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="收货地址">
        {{
          `${detailsObj.tradeOrder.receivingAddress || ""} (${
            detailsObj.tradeOrder.receivingPerson || ""
          }) ${detailsObj.tradeOrder.receivingPhone}`
        }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.tradeOrder.merchantDistribution === 1"
        label="运输信息"
      >
        {{
          `${detailsObj.tradeOrder.carCode || ""} ${
            detailsObj.tradeOrder.driverPerson || ""
          } ${detailsObj.tradeOrder.driverPhone || ""}`
        }}
      </el-descriptions-item>
      <el-descriptions-item v-else label="自提地址">
        {{
          `${detailsObj.tradeOrder.warehouseAddress || ""} ${
            detailsObj.tradeOrder.warehouseName || ""
          } ${detailsObj.tradeOrder.warehousePerson || ""} ${
            detailsObj.tradeOrder.warehousePhone || ""
          }`
        }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 配送信息 -->
    <el-descriptions title="支付信息" :column="3">
      <el-descriptions-item
        label="支付方式"
      >
        {{
          detailsObj.tradeOrder.payStyle === "00"
            ? "全款支付"
            : "保证金预付"
        }}
        <router-link
          class="lookOrder"
          :to="{
            path: '/accountManagementSystem/fundDetails',
            query: {
              cmpId: detailsObj.orderNodeList&&detailsObj.orderNodeList.length>0?detailsObj.orderNodeList[0].cmpId:'',
              settleBankId: detailsObj.tradeOrder.settleBankId
            },
          }"
        >
          查看账单
        </router-link>
      </el-descriptions-item>
      <el-descriptions-item
        label="付款账户"
      >
        {{
          detailsObj.tradeOrder.settleBankName
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="收款账户"
      >
        {{
          detailsObj.tradeOrder.settleBankName
        }}
      </el-descriptions-item>
    </el-descriptions>

    <!-- 提货记录 -->
    <el-descriptions
      v-if="detailsObj.takeGoodsBillList && detailsObj.takeGoodsBillList.length > 0"
      title="提货记录"
      :column="3"
    >
      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3" label="">
        <Table
          :item-data="itemData"
          :list-data="detailsObj.takeGoodsBillList"
          :operation-button="operationConfig.pickOperationButton"
          :loading="loading"
        />
      </el-descriptions-item>
    </el-descriptions>
    <!-- 过户记录 -->
    <el-descriptions
      v-if="detailsObj.transferBillList && detailsObj.transferBillList.length > 0"
      title="过户记录"
      :column="3"
    >
      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3" label="">
        <Table
          :item-data="itemDataTransfer"
          :list-data="detailsObj.transferBillList"
          :operation-button="operationConfig.transferOperationButton"
          :loading="loading"
        />
      </el-descriptions-item>
    </el-descriptions>
    <!-- 订单节点 -->
    <el-descriptions title="订单节点" :column="3">
      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3" label="">
        <div class="elTimelineBox">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in detailsObj.orderNodeList"
              :key="index"
              :timestamp="activity.orderOperateTime"
            >
              <div>
                {{ activity.orderOperate }}
                ({{
                  activity.orderOperateObject === "00"
                    ? "买家"
                    : activity.orderOperateObject === "01"
                      ? "卖家"
                      : "仓管"
                }}
                {{ activity.orderOperatePerson }})
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <!-- 购销合同 -->
    <el-descriptions title="购销合同" :column="3">
      <el-descriptions-item label-class-name="labelHidden" :span="3" label="">
        <div v-if="detailsObj.tradeOrder.contractUrl" class="flexBox">
          <img src="@/assets/img/fileType1.png" alt="fileType1">
          <span>{{ detailsObj.tradeOrder.contractName }}</span>
          <span
            class="download-btn"
            type="primary"
            link
            @click="
              downloadPdf(
                $fileUrl + detailsObj.tradeOrder.contractUrl,
                detailsObj.tradeOrder.contractName
              )
            "
          >下载</span>
          <a
            :href="$fileUrl + detailsObj.tradeOrder.contractUrl"
            target="_blank"
          ><span type="primary" link>查看</span></a>
        </div>
        <div v-else class="flexBox">
          <span>暂无</span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { tradeOrderDetail } from '@/api/shoppingMall'
import { floatAdd, getBankName, downloadFile } from '@/utils/util'
export default {
  components: { Table },
  data() {
    return {
      listData: [],
      temporaryCmpRole: '',
      total: 0,
      loading: false,
      unit: '',
      productLists: [],
      goodsItemData: [
        { label: '品名', prop: 'goodsTwoCategoryName' },
        { label: '材质', prop: 'goodsMaterialName' },
        { label: '规格', prop: 'goodsSpecName' },
        { label: '产地/厂家', prop: 'manufactor' },
        { label: '数量/单位', prop: 'goodsWeight', unit: '吨' },
        { label: '含税单价（元）', prop: 'unitPriceIncludingTax', width: 180 },
        { label: '小计（元）', prop: 'goodsAmount', width: 180 }
      ],
      itemData: [
        { label: '提货单号', prop: 'takeGoodsNo', width: 180 },
        { label: '申请时间', prop: 'applyTime', width: 180 },
        {
          label: '申请提货数量/单位',
          prop: 'applyTakeWeight',
          width: 180,
          unit: '吨'
        },
        {
          label: '剩余数量/单位',
          prop: 'remainingWeight',
          width: 180,
          unit: '吨'
        },
        {
          label: '提货单状态',
          prop: 'state',
          child: this.$store.getters.getDictionaryItem('take_goods_bill_status'),
          width: 100
        }
      ],
      itemDataTransfer: [
        { label: '过户单号', prop: 'goodsTransferNo', width: 180 },
        { label: '申请时间', prop: 'transferApplyTime', width: 180 },
        {
          label: '申请过户数量/单位',
          prop: 'transferWeight',
          width: 180,
          unit: '吨'
        },
        {
          label: '剩余数量/单位',
          prop: 'remainingWeight',
          width: 180,
          unit: '吨'
        },
        {
          label: '过户状态',
          prop: 'transferStatus',
          child: this.$store.getters.getDictionaryItem('transfer_bill_status'),
          width: 100
        }
      ],
      operationConfig: {
        pickOperationButton: [
          { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
        ],
        transferOperationButton: [
          { bType: 'primary', label: '查看库存', handleEvent: this.viewStock }
        ]
      },
      dictionary: this.$store.getters.getDictionaryItem('order_status'),
      detailsObj: {
        tradeOrder: {}
      }
    }
  },
  watch: {
    productLists: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.unit = newVal[0].unitMeasurement || '吨'
          this.goodsItemData.forEach(item => {
            if (item.unit)item.unit = this.unit
          })
          this.itemDataTransfer.forEach(item => {
            if (item.unit)item.unit = this.unit
          })
          this.itemData.forEach(item => {
            if (item.unit)item.unit = this.unit
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      tradeOrderDetail(this.$route.query.id, (res) => {
        if (!res.data) return
        this.detailsObj = res.data
        this.detailsObj.tradeOrder.orderTotalAmount = this.detailsObj
          .tradeOrder.orderTotalAmount
          ? this.detailsObj.tradeOrder.orderTotalAmount
          : floatAdd(
            this.detailsObj.tradeOrder.orderAmount,
            this.detailsObj.tradeOrder.orderFreight
          )
        this.detailsObj.totalWeight = 0
        this.detailsObj.productLists =
          this.detailsObj.productLists.map((item, index) => {
            this.detailsObj.totalWeight += Number(item.goodsWeight)
            item.orderFreight = res.data.tradeOrder.orderFreight
            item.bondRate = res.data.tradeOrder.bondRate
              ? res.data.tradeOrder.bondRate + '%'
              : '100%'
            return item
          })
        this.productLists = this.detailsObj.productLists.slice(0, 5)
        this.detailsObj.tradeOrder.settleBankName = getBankName(
          this.detailsObj.tradeOrder.settleBankId
        )
      })
    },
    // 下载
    downloadPdf(url, name) {
      downloadFile(url, name)
    },
    getMoreData() {
      this.productLists = [...this.detailsObj.productLists]
    },
    // 提货详情查看
    viewDetails({ id }) {
      this.$router.push({
        path: '/shoppingMall/pickingDetails',
        query: { id }
      })
    },
    // 查看库存
    viewStock({ id }) {
      this.$router.push({
        path: '/warehousingSystem/inventoryManagement',
        query: { id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details-demo {
  background-color: #fff;
  .order-state {
    margin-bottom: 20px;
  }
  .flexBox {
    display: flex;
    align-items: center;
    margin-left: 32px;
    span {
      margin: 0 12px;
    }
    img {
      height: 24px;
      width: 24px;
    }
  }
  .flexBox2 {
    flex: 1;
    justify-content: space-between;
    margin-left: 0;
    p {
      font-size: 14px;
    }
  }
  .amount {
    width: 330px;
    padding: 18px 0;
    background-color: #fff;
    border: 1px solid #0089FF;
    align-self: end;
    z-index: 1;
    p {
      color: #35383D;
      font-size: 12px;
      padding-left: 34px;
      padding-bottom: 11px;
      span {
        display: inline-block;
        width: 84px;
        // color: $fontColor1;
        font-weight: 500;
        font-size: 12px;
      }
    }
    p:last-of-type {
      padding-bottom: 0;
    }
    .price {
      font-size: 18px;
      font-weight: 800;
      color: #0089FF;
    }
  }
  .elTimelineBox {
    padding-left: 32px;
    max-height: 220px;
    overflow-y: auto;
    margin-bottom: 24px;
    width: 100%;
    :deep(.el-timeline-item:first-of-type){

       .el-timeline-item__node{
        background-color: #0089FF;
       }
       .el-timeline-item__timestamp ,.el-timeline-item__wrapper .el-timeline-item__content{
         color: #0089FF;
       }
    }
  }
  .orderNode {
    padding-left: 24px;
  }
  .tableBox {
    margin-left: 32px;
    .more {
      text-align: center;
      transform: translateY(-12px);
      color: #666;
      .el-icon {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .distribution-info {
    margin-top: 20px;
  }
  .lookOrder {
    color: #0089FF;
  }
}
/deep/ .labelHidden {
  display: none;
}
/deep/ .table-descriptions {
  width: 100%;
}
.download-btn {
  margin-right: 10px;
  color: #0089FF;
  cursor: pointer;
}
a {
  color: #0089FF;
}
</style>
